<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4 pv3">
        <password-reset v-if="isPasswordReset" />
        <password-reset-success v-else />
      </div>
    </article>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PasswordReset from "../components/PasswordReset.vue";
import PasswordResetSuccess from "../components/PasswordResetSuccess.vue";
export default {
  name: "password-reset-view",
  components: {
    "password-reset": PasswordReset,
    "password-reset-success": PasswordResetSuccess
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters("auth", {
      isPasswordReset: "isPasswordReset"
    })
  },
  mounted() {},
  created() {}
};
</script>

<style lang="stylus"></style>
