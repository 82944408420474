<template>
  <div>
    <div class="tc">
      <div class="ba b--light-gray bw2">
        <h1 class="f3 f2-ns ttu vidaloka">
          {{ $t("reset_password.reset_title") }}
        </h1>
      </div>
    </div>
    <article class="pkarla tracked-light lh-copy">
      <p class="tc">{{ $t("reset_password.reset_article") }}</p>

      <div v-if="loading">Loading...</div>
      <div v-if="error" class="dark-red">{{ error }}</div>
      <form
        v-on:submit.prevent="passwordReset(email)"
        class="measure center tl"
      >
        <fieldset class="ba b--transparent ph0 mh0">
          <div>
            <!-- Email -->
            <label class="db fw6 lh-copy f6" for="password">{{
              $t("reset_password.email")
            }}</label>
            <input
              name="email"
              id="email"
              class="pa2 input-reset ba bg-transparent w-100"
              type="email"
              v-model="email"
              placeholder
            />
          </div>
        </fieldset>
        <button
          class="w-100 f6 fw6 dim dib ttu tracked b white bg-black link tc ph3 pv2 lh-copy roboto sans-serif"
          :disabled="!email"
        >
          {{ $t("reset_password.submit") }}
        </button>
      </form>
    </article>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "password-reset",
  props: {},
  components: {},
  data() {
    return {
      email: null
    };
  },
  methods: {
    passwordReset(email) {
      this.$store.dispatch("auth/forgotPassword", {
        email: email,
        language: this.$route.params.lang
      });
    }
  },
  computed: {
    ...mapState({
      error: state => state.auth.error,
      loading: state => state.auth.loading
    })
  },
  mounted() {},
  created() {}
};
</script>
