<template>
  <div>
    <div class="tc">
      <div class="ba b--light-gray bw2 mh1-l mh3">
        <h1 class="tc pt2 ttu vidaloka">
          {{ $t("reset_password.reset_success_title") }}
        </h1>
      </div>
    </div>
    <article class="karla tracked-light lh-copy">
      <p class="tc">{{ $t("reset_password.reset_success_article") }}</p>

      <button
        class="w-100 f6 fw6 dim dib ttu tracked b white bg-black link tc ph3 pv2 mb2 lh-copy roboto sans-serif"
        v-on:click.once="toSignIn()"
      >
        {{ $t("reset_password.return_to_sign_in") }}
      </button>
    </article>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "password-reset",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {
    toSignIn() {
      this.$store.dispatch("auth/toSignIn", {
        router: this.$router
      });
    }
  },
  computed: {},
  mounted() {},
  created() {}
};
</script>
